/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import { TmappingConfigs } from '../../../../types/Tmappings'

const useAllHooks = (
  setConfigs: (arg0: TmappingConfigs) => void,
  initialValues: TmappingConfigs,
) => {
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [publishDate, setPublishDate] = useState('')
  const [showUrl, setShowUrl] = useState('')
  const [embeddedHtml, setEmbeddedHtml] = useState('')
  const [referenceNumber, setReferenceNumber] = useState('')
  const [zipUrl, setZipUrl] = useState('')
  const [company, setCompany] = useState('')
  const [applyUrl, setApplyUrl] = useState('')
  const [applyEmail, setApplyEmail] = useState('')
  const [language, setLanguage] = useState('')
  const [headcount, setHeadcount] = useState('')
  // add industry id, field of work id, seniority id, position type id
  const [industryId, setIndustryId] = useState('')
  const [industryTitle, setIndustryTitle] = useState('')
  const [fieldOfWorkId, setFieldOfWorkId] = useState('')
  const [fieldOfWorkTitle, setFieldOfWorkTitle] = useState('')
  const [contractTypeId, setContractTypeId] = useState('')
  const [contractTypeTitle, setContractTypeTitle] = useState('')
  const [seniorityId, setSeniorityId] = useState('')
  const [seniorityTitle, setSeniorityTitle] = useState('')
  const [positionTypeId, setPositionTypeId] = useState('')
  const [positionTypeTitle, setPositionTypeTitle] = useState('')
  const [employmentStartDate, setEmploymentStartDate] = useState('')
  const [keywords, setKeywords] = useState('')
  const [locationContext, setLocationContext] = useState('')
  // location
  const [locationCity, setLocationCity] = useState('')
  const [locationZip, setLocationZip] = useState('')
  const [locationStreet, setLocationStreet] = useState('')
  const [locationStreetNumber, setLocationStreetNumber] = useState('')
  const [locationRegion, setLocationRegion] = useState('')
  const [locationCountry, setLocationCountry] = useState('')
  const [locationCountryCode, setLocationCountryCode] = useState('')
  // position types
  const [positionTypesContext, setPositionTypesContext] = useState('')
  const [positionTypesId, setPositionTypesId] = useState('')
  const [positionTypesTitle, setPositionTypesTitle] = useState('')

  const [combinedPositionTypes, setCombinedPositionTypes] = useState('')

  // salary
  const [salaryMinimum, setSalaryMinimum] = useState('')
  const [salaryMaximum, setSalaryMaximum] = useState('')
  const [salaryCurrency, setSalaryCurrency] = useState('')
  const [salaryInterval, setSalaryInterval] = useState('')
  // contact
  const [contactName, setContactName] = useState('')
  const [contactUrl, setContactUrl] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  // content
  const [hiringOrganizationTitle, setHiringOrganizationTitle] = useState('')
  const [hiringOrganization, setHiringOrganization] = useState('')
  const [responsibilitiesTitle, setResponsibilitiesTitle] = useState('')
  const [responsibilities, setResponsibilities] = useState('')
  const [qualificationsTitle, setQualificationsTitle] = useState('')
  const [qualifications, setQualifications] = useState('')
  const [incentivesTitle, setIncentivesTitle] = useState('')
  const [incentives, setIncentives] = useState('')
  const [contentContactTitle, setContentContactTitle] = useState('')
  const [contentContact, setContentContact] = useState('')
  // branding
  const [primaryColor, setPrimaryColor] = useState('')
  const [secondaryColor, setSecondaryColor] = useState('')
  const [headerImage, setHeaderImage] = useState('')
  const [backgroundImage, setBackgroundImage] = useState('')
  const [video, setVideo] = useState('')
  const [sliderImages, setSliderImages] = useState('')
  const [logo, setLogo] = useState('')

  const uniqueId = '67988'
  let mappingsConfig: TmappingConfigs

  useEffect(() => {
    let _applyUrl = applyUrl
    if (!applyUrl) _applyUrl = showUrl

    mappingsConfig = {
      id,
      title,
      publishDate,
      showUrl,
      embeddedHtml,
      referenceNumber,
      zipUrl,
      company,
      applyUrl: _applyUrl,
      applyEmail,
      language,
      headcount,
      employmentStartDate,
      keywords,
      locationContext,
      industry: { id: industryId, title: industryTitle },
      fieldOfWork: { id: fieldOfWorkId, title: fieldOfWorkTitle },
      contractType: { id: contractTypeId, title: contractTypeTitle },
      positionType: { id: positionTypeId, title: positionTypeTitle },
      seniority: { id: seniorityId, title: seniorityTitle },
      location: {
        city: locationCity,
        zip: locationZip,
        street: locationStreet,
        streetNumber: locationStreetNumber,
        region: locationRegion,
        country: locationCountry,
        countryCode: locationCountryCode,
      },
      positionTypesContext,
      positionTypes: { id: positionTypesId, title: positionTypesTitle },
      combinedPositionTypes,
      salary: {
        minimum: salaryMinimum,
        maximum: salaryMaximum,
        currency: salaryCurrency,
        interval: salaryInterval,
      },
      contact: {
        name: contactName,
        url: contactUrl,
        email: contactEmail,
        phone: contactPhone,
      },
      content: {
        hiringOrganizationTitle,
        hiringOrganization,
        responsibilitiesTitle,
        responsibilities,
        qualificationsTitle,
        qualifications,
        incentivesTitle,
        incentives,
        contactTitle: contentContactTitle,
        contact: contentContact,
      },
      branding: {
        primaryColor,
        secondaryColor,
        headerImage,
        backgroundImage,
        video,
        sliderImages,
        logo,
      },
    }

    setConfigs(mappingsConfig)
  }, [
    id,
    title,
    publishDate,
    showUrl,
    embeddedHtml,
    referenceNumber,
    zipUrl,
    applyUrl,
    applyEmail,
    language,
    headcount,
    industryId,
    industryTitle,
    fieldOfWorkTitle,
    fieldOfWorkId,
    contractTypeId,
    contractTypeTitle,
    positionTypeId,
    positionTypeTitle,
    employmentStartDate,
    keywords,
    locationContext,
    locationCity,
    locationZip,
    locationStreet,
    locationStreetNumber,
    locationRegion,
    locationCountry,
    locationCountryCode,
    positionTypesContext,
    positionTypesId,
    positionTypesTitle,
    combinedPositionTypes,
    seniorityId,
    seniorityTitle,
    salaryMinimum,
    salaryMaximum,
    salaryCurrency,
    salaryInterval,
    contactName,
    contactUrl,
    contactEmail,
    contactPhone,
    hiringOrganization,
    responsibilities,
    qualifications,
    incentives,
    contentContact,
    primaryColor,
    secondaryColor,
    headerImage,
    backgroundImage,
    video,
    sliderImages,
    company,
    hiringOrganizationTitle,
    responsibilitiesTitle,
    qualificationsTitle,
    incentivesTitle,
    contentContactTitle,
    logo,
  ])

  useEffect(() => {
    if (initialValues) {
      const {
        id,
        title,
        publishDate,
        showUrl,
        embeddedHtml,
        referenceNumber,
        zipUrl,
        applyUrl,
        applyEmail,
        language,
        headcount,
        industry,
        fieldOfWork,
        contractType,
        positionType,
        employmentStartDate,
        keywords,
        locationContext,
        location,
        positionTypesContext,
        combinedPositionTypes,
        salary,
        contact,
        content,
        branding,
        company,
        seniority,
      } = initialValues
      if (!applyUrl) setApplyUrl(showUrl)
      if (zipUrl) setZipUrl(zipUrl)

      setId(id)
      setTitle(title)
      setPublishDate(publishDate)
      setShowUrl(showUrl)
      setEmbeddedHtml(embeddedHtml)
      setReferenceNumber(referenceNumber)
      setCompany(company)
      setApplyUrl(applyUrl)
      setApplyEmail(applyEmail)
      setLanguage(language)
      setHeadcount(headcount)
      setSeniorityId(seniority.id)
      setSeniorityTitle(seniority.title)
      setEmploymentStartDate(employmentStartDate)
      setKeywords(keywords)
      setLocationContext(locationContext)
      setPositionTypesContext(positionTypesContext)
      setPositionTypeId(positionType.id)
      setPositionTypeTitle(positionType.title)
      setPositionTypeId(positionType.id)
      setPositionTypeTitle(positionType.title)
      setCombinedPositionTypes(combinedPositionTypes)
      setIndustryId(industry.id)
      setIndustryTitle(industry.title)
      setFieldOfWorkId(fieldOfWork.id)
      setFieldOfWorkTitle(fieldOfWork.title)
      setContractTypeId(contractType?.id)
      setContractTypeTitle(contractType?.title)
      setLocationCity(location.city)
      setLocationZip(location.zip)
      setLocationStreet(location.street)
      setLocationStreetNumber(location.streetNumber)
      setLocationRegion(location.region)
      setLocationCountry(location.country)
      setLocationCountryCode(location.countryCode)
      setSalaryMinimum(salary.minimum)
      setSalaryMaximum(salary.maximum)
      setSalaryCurrency(salary.currency)
      setSalaryInterval(salary.interval)
      setContactName(contact.name)
      setContactUrl(contact.url)
      setContactEmail(contact.email)
      setContactPhone(contact.phone)
      setHiringOrganizationTitle(content.hiringOrganizationTitle)
      setHiringOrganization(content.hiringOrganization)
      setResponsibilitiesTitle(content.responsibilitiesTitle)
      setResponsibilities(content.responsibilities)
      setQualificationsTitle(content.qualificationsTitle)
      setQualifications(content.qualifications)
      setIncentivesTitle(content.incentivesTitle)
      setIncentives(content.incentives)
      setContentContactTitle(content.contactTitle)
      setContentContact(content.contact)
      setPrimaryColor(branding.primaryColor)
      setSecondaryColor(branding.secondaryColor)
      setHeaderImage(branding.headerImage)
      setBackgroundImage(branding.backgroundImage)
      setVideo(branding.video)
      setSliderImages(branding.sliderImages)
      setLogo(branding.logo)
    }
  }, [initialValues])

  return {
    mappingsConfig,
    id,
    setId,
    title,
    setTitle,
    publishDate,
    setPublishDate,
    showUrl,
    setShowUrl,
    embeddedHtml,
    setEmbeddedHtml,
    referenceNumber,
    setReferenceNumber,
    zipUrl,
    setZipUrl,
    company,
    setCompany,
    applyUrl,
    setApplyUrl,
    applyEmail,
    setApplyEmail,
    language,
    setLanguage,
    headcount,
    setHeadcount,
    industryTitle,
    setIndustryTitle,
    fieldOfWorkTitle,
    setFieldOfWorkTitle,
    contractTypeTitle,
    setContractTypeTitle,
    contractTypeId,
    setContractTypeId,
    seniorityTitle,
    setSeniorityTitle,
    positionTypeTitle,
    setPositionTypeTitle,
    combinedPositionTypes,
    setCombinedPositionTypes,
    employmentStartDate,
    setEmploymentStartDate,
    keywords,
    setKeywords,
    locationContext,
    setLocationContext,
    locationCity,
    setLocationCity,
    locationZip,
    setLocationZip,
    locationStreet,
    setLocationStreet,
    locationStreetNumber,
    setLocationStreetNumber,
    locationRegion,
    setLocationRegion,
    locationCountry,
    setLocationCountry,
    locationCountryCode,
    setLocationCountryCode,
    positionTypesContext,
    setPositionTypesContext,
    positionTypesId,
    setPositionTypesId,
    positionTypesTitle,
    setPositionTypesTitle,
    salaryMinimum,
    setSalaryMinimum,
    salaryMaximum,
    logo,
    setLogo,
    setSalaryMaximum,
    sliderImages,
    setSliderImages,
    salaryCurrency,
    video,
    setVideo,
    setSalaryCurrency,
    backgroundImage,
    setBackgroundImage,
    salaryInterval,
    headerImage,
    setHeaderImage,
    setSalaryInterval,
    secondaryColor,
    setSecondaryColor,
    contactName,
    primaryColor,
    setPrimaryColor,
    uniqueId,
    setContactName,
    contentContact,
    setContentContact,
    contactUrl,
    contentContactTitle,
    setContentContactTitle,
    setContactUrl,
    incentives,
    setIncentives,
    contactEmail,
    incentivesTitle,
    setIncentivesTitle,
    setContactEmail,
    qualifications,
    setQualifications,
    contactPhone,
    qualificationsTitle,
    setQualificationsTitle,
    setContactPhone,
    responsibilities,
    setResponsibilities,
    hiringOrganizationTitle,
    responsibilitiesTitle,
    setResponsibilitiesTitle,
    setHiringOrganizationTitle,
    hiringOrganization,
    setHiringOrganization,
    industryId,
    setIndustryId,
    fieldOfWorkId,
    setFieldOfWorkId,
    seniorityId,
    setSeniorityId,
    positionTypeId,
    setPositionTypeId,
  }
}

export default useAllHooks
